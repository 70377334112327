import Reservation from "./components/Reservation";
import GoToReservation from "./components/GoToReservation";
import Reveal from "./components/AnimReveal";

export default function JelajahJivaBestari() {
  return (
    <div>
      <div className="bg-purple pb-4 md:pb-0">
        <div
          className="bg-center bg-cover"
          style={{
            backgroundImage: `url('/img/jelajah-jiva-1.jpg')`,
            height: "calc(100vh - 50px)",
          }}
        />
        <GoToReservation />

        <div className="container pl-3 pr-3 text-white md:pl-0 md:pr-0 md:text-center md:pt-13 md:pb-13">
          <div className="pt-3 mb-3 text-32 md:pt-0 md:mb-0 md:text-48 font-f-2">
            <Reveal>Jelajah Rasa Dewantara</Reveal>
          </div>
          <div className="ml-auto mr-auto text-left md:text-center md:pt-4 md:pl-3 md:w-7/12">
            <div className="text-16 md:text-21">
              <Reveal>
                <p>
                  Bersama Dewantara seorang chef muda yang akan mengajak anda
                  menjelajahi Indonesia untuk menemukan resep-resep lengendaris
                  turun menurun yang menjadi favorit nenek dan ibunya. Dalam
                  perjalannya menemukan resep legendaris, Dewantara akan
                  mengajak anda untuk ikut menikmati sajian nusantara yang
                  selama ini telah ia rindukan.
                </p>{" "}
                <p>
                  Dengan tayangan animasi dan video mapping akan membuat anda
                  merasakan pengalaman berbeda saat mengikuti perjalanan
                  Dewantara. Kami akan membuat anda seolah-olah sedang berpindah
                  tempat untuk mencari resep legendaris dengan menyelipkan
                  music, ornamen dan suasana khas daerah.
                </p>{" "}
                 
                <p>
                  {" "}
                  Perjalanan Dewantara akan berlangsung selama 60 menit dan
                  diikuti oleh maksimal 6 orang dalam satu kali perjalanan,
                  setiap harinya Dewantara akan melakukan perjalanan ini selama
                  2x di jam 12.00 dan 14.00
                </p>
              </Reveal>
            </div>
          </div>
        </div>
      </div>
      <img
        src="/img/jelajah-jiva-2.jpg"
        width="100%"
        className="w-full ml-auto mr-auto"
        alt="menu-2"
      />
      <div className="hidden md:block">
        <Reservation />
      </div>
    </div>
  );
}
