export default function Input(props: {
  label: string;
  placeholder?: string;
  onChange: (e: string) => void;
  value: string;
  note?: string;
}) {
  return (
    <div className="w-full pl-2 pr-2 relative">
      <div className="text-left md:pb-2 text-16 text-purple md:text-32">
        {props.label}
      </div>
      <div className="mb-3 border-b md:mb-0 border-purple">
        <input
          placeholder={props.placeholder || ""}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          className="w-full pb-1 bg-transparent outline-none font-f-2 text-purple md:text-24"
        />
        <div className="absolute pt-1" style={{ color: "red" }}>
          {props.note}
        </div>
      </div>
    </div>
  );
}
