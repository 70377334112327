import Reservation from "./components/Reservation";
import GoToReservation from "./components/GoToReservation";

import { ReactComponent as Instagram } from "./svg/instagram.svg";
import { ReactComponent as Youtube } from "./svg/youtube.svg";
import Reveal from "./components/AnimReveal";
import useData from "./hooks/useData";
import ErrorPage from "./components/ErrorPage";

export default function HubungiKami() {
  const { data, loading } = useData("/singletons/get/page_hubungi_kami");
  if (loading) return null;
  if (!loading && !data) return <ErrorPage />;

  return (
    <div>
      <div className="bg-purple">
        <div
          className="bg-center bg-cover"
          style={{
            backgroundImage: `url(${
              process.env.REACT_APP_BASE_URL + data.banner.path
            })`,
            height: "calc(100vh - 50px)",
          }}
        />
        <GoToReservation />
        <div className="container text-center text-white md:pt-17 md:pb-17">
          <div className="md:flex">
            <div className="w-8/12 pr-3">
              <div className="hidden mapouter md:block">
                <div className="gmap_canvas">
                  <iframe
                    width="710"
                    height="554"
                    id="gmap_canvas"
                    src="https://maps.google.com/maps?q=SMK%20PGRI%202%20KUDUS,%20Kedungdowo,%20Kec.%20Kaliwungu,%20Kabupaten%20Kudus,%20Jawa%20Tengah%2059332&t=&z=13&ie=UTF8&iwloc=&output=embed"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight={0}
                    marginWidth={0}
                  />
                </div>
              </div>
            </div>
            <div className="pt-4 pl-2 pr-2 text-left md:pr-0 md:pt-0 md:w-4/12 md:pl-3">
              <div className="pb-3 text-32 md:text-48 font-f-2">
                <Reveal>{data.title}</Reveal>
              </div>
              <div className="text-16 md:text-21">
                <Reveal>
                  <p className="whitespace-pre-wrap">
                    {data.description}
                    <br />
                    <br />
                    <a
                      target="_blank"
                      className="underline"
                      href="https://goo.gl/maps/C5ByCpPkjEUtuX4QA"
                    >
                      Buka di Google Maps{" "}
                    </a>
                    <br />
                    <br />
                  </p>
                </Reveal>
                <div className="mapouter md:hidden">
                  <div className="gmap_canvas">
                    <iframe
                      width="100%"
                      height="250"
                      id="gmap_canvas"
                      src="https://maps.google.com/maps?q=SMK%20PGRI%202%20KUDUS,%20Kedungdowo,%20Kec.%20Kaliwungu,%20Kabupaten%20Kudus,%20Jawa%20Tengah%2059332&t=&z=13&ie=UTF8&iwloc=&output=embed"
                      frameBorder="0"
                      scrolling="no"
                      marginHeight={0}
                      marginWidth={0}
                    />
                  </div>
                </div>
                <div className="mt-3 mb-3 md:hidden text-16">
                  <Reveal>Hubungi kami</Reveal>
                </div>
                <div className="table pb-6 md:pb-0" style={{ lineHeight: 1 }}>
                  <Reveal>
                    <div className="flex pb-1">
                      <span style={{ width: 100 }}>Telepon</span>
                      <span
                        style={{ width: 32, bottom: -1 }}
                        className="relative flex items-center"
                      >
                        <hr className="w-full" />
                      </span>
                      <span className="pl-3">{data.phone_number}</span>
                    </div>
                    <div className="flex pb-1">
                      <span style={{ width: 100 }}>Whatsapp</span>
                      <span
                        style={{ width: 32, bottom: -1 }}
                        className="relative flex items-center"
                      >
                        <hr className="w-full" />
                      </span>
                      <span className="pl-3">{data.wa_number}</span>
                    </div>
                    <div className="flex pb-1">
                      <span style={{ width: 100 }}>Email</span>
                      <span
                        style={{ width: 32, bottom: -1 }}
                        className="relative flex items-center"
                      >
                        <hr className="w-full" />
                      </span>
                      <span className="pl-3">
                        <a href={`mailto:${data.email}`}>{data.email}</a>
                      </span>
                    </div>
                  </Reveal>
                </div>
                <div className="hidden mt-6 md:block">
                  <Reveal>Ikuti Kami</Reveal>
                </div>
                <Reveal>
                  <div className="flex hidden mt-2 icon-white md:block">
                    <a
                      href="https://www.instagram.com/jivabestari/"
                      title="instagram"
                      target="_blank"
                      className="inline-block mr-2"
                    >
                      <Instagram />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCWOPL-jzz_EavruiifHzF1g"
                      target="_blank"
                      title="youtube"
                      className="inline-block mr-2"
                    >
                      <Youtube />
                    </a>
                  </div>
                </Reveal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden md:block">
        <Reservation />
      </div>
    </div>
  );
}
