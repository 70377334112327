import { useState, useEffect, useRef } from "react";
import { ReactComponent as Chevron } from "../svg/chevron-down.svg";

export default function Select(props: {
  label: string;
  selected: string;
  setSelected: (value: any) => void;
  options: any[];
}) {
  const [show, setShow] = useState(false);
  const ref: any = useRef(null);
  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return (
    <div className="relative w-full pl-2 pr-2 select" ref={ref}>
      <button onClick={() => setShow(!show)} className="w-full">
        <div className="pb-2 text-left text-purple text-16 md:text-32">
          {props.label}
        </div>
        <div className="flex items-center justify-between pb-1 mb-2 border-b md:mb-0 border-purple">
          <span className="text-purple text-16 font-f-2 md:text-24">
            {props.selected || "Pilih"}
          </span>
          <Chevron />
        </div>
      </button>
      {show && (
        <div
          className="absolute z-50 bg-lightPink"
          style={{
            width: "calc(100% - 40px)",
            overflow: "auto",
            maxHeight: 230,
          }}
        >
          {props.options.map((option: any, index: number) => {
            return (
              <div key={index}>
                <button
                  onClick={() => {
                    props.setSelected(option);
                    setShow(false);
                  }}
                  className={`w-full p-2 text-left text-purple text-13 md:text-21 font-f-2 hover-font-bold ${
                    props.selected === option ? "font-bold" : ""
                  }`}
                >
                  {option}
                </button>
                {index !== props.options.length - 1 && (
                  <div className="pl-2 pr-2">
                    <hr className="border-purple" />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
