import { ReactComponent as LongArrowLeft } from "../svg/long-arrow-left.svg";
import { ReactComponent as LongArrowRight } from "../svg/long-arrow-right.svg";
import { ReactComponent as ChevcronDown } from "../svg/chevron-down.svg";
import Reveal from "../components/AnimReveal";
import { useState, useRef, useCallback, useEffect } from "react";
import { useTransition, animated as anim } from "react-spring";
import { Spring, animated } from "react-spring/renderprops";
import getImage from "../utils";

const mod = (n: any, m: any) => ((n % m) + m) % m;

export default function Accordion(props: {
  label: string;
  show: boolean;
  gallery: { img: string; desc: string }[];
  menus: { title: string; price: string; desc: string }[];
  onChange: (val: string) => void;
}) {
  const [contentMaxHeight, setContentMaxHeight] = useState(0);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const ref: any = useRef();

  useEffect(() => {
    const calcContentMaxHeight = () => {
      ref && setContentMaxHeight(ref.current.scrollHeight);
    };

    calcContentMaxHeight();

    window.addEventListener("resize", () => calcContentMaxHeight());

    return () => window.removeEventListener("resize", calcContentMaxHeight);
  }, [ref, contentMaxHeight]);

  const handlePrev = useCallback(() => {
    setCarouselIndex((state) => mod(state - 1, props.gallery.length));
  }, [setCarouselIndex]);

  const handleNext = useCallback(() => {
    setCarouselIndex((state) => mod(state + 1, props.gallery.length));
  }, [setCarouselIndex]);

  const transitions = useTransition([carouselIndex], null, {
    from: { position: "absolute", opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <div className="text-left text-white">
      <Reveal>
        <button
          onClick={() => props.onChange(props.show ? "" : props.label)}
          className="flex items-center justify-between w-full pt-2 pb-2 md:pt-3 md:pb-4 text-18 md:text-40"
        >
          {props.label} <ChevcronDown />
        </button>
      </Reveal>
      <Spring
        native
        config={{ duration: 300 }}
        to={{
          opacity: props.show ? 1 : 0,
          height: props.show ? `${contentMaxHeight + 30}px` : "0px",
        }}
      >
        {(style) => (
          <animated.div style={{ ...style, overflow: "hidden" }}>
            <div ref={ref} className="md:pb-4">
              {props.gallery.length > 0 && (
                <Reveal>
                  <div className="flex justify-between">
                    <div className="flex items-center">
                      <button
                        onClick={handlePrev}
                        className={`relative z-10 -mr-2 md:-mt-5 menu-arrow ${
                          props.gallery.length == 1 ? "hidden" : "block"
                        }`}
                      >
                        <LongArrowLeft />
                      </button>
                    </div>
                    <div className="relative menu-container">
                      {transitions.map((trans) => {
                        const image = props.gallery[trans.item];
                        return (
                          <anim.div key={trans.key} style={{ ...trans.props }}>
                            <div>
                              <img src={getImage(image.img)} width="100%" />
                            </div>
                            <div className="mt-2 md:block text-13 md:text-21">
                              {image.desc}
                            </div>
                          </anim.div>
                        );
                      })}
                    </div>
                    <div className="flex items-center">
                      <button
                        onClick={handleNext}
                        // className="relative z-10 -ml-2 md:-mt-5 menu-arrow"
                        className={`relative z-10 -mr-2 md:-mt-5 menu-arrow ${
                          props.gallery.length == 1 ? "hidden" : "block"
                        }`}
                      >
                        <LongArrowRight />
                      </button>
                    </div>
                  </div>
                </Reveal>
              )}
              <div className="flex flex-wrap mt-4">
                {props.menus.map((menu, index) => {
                  return (
                    <div key={index} className="md:w-6/12">
                      <Reveal>
                        <div className="md:text-32 text-16">
                          {menu.title}
                          {menu.price && (
                            <span className="ml-1 text-pink text-18 md:text-32">
                              {menu.price}
                            </span>
                          )}
                        </div>
                        {menu.desc && (
                          <div className="pr-4 mb-3 text-16 md:text-21">
                            {menu.desc}
                          </div>
                        )}
                      </Reveal>
                    </div>
                  );
                })}
              </div>
            </div>
          </animated.div>
        )}
      </Spring>
      <Reveal>
        <hr />
      </Reveal>
    </div>
  );
}
