import { useEffect, useState } from "react";
import { ReactComponent as ClosePink } from "../svg/close-pink.svg";
import Reservation from "./Reservation";

export default function ButtonReservation() {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const w: any = window;
    w.showReservation = () => setShow(true);
  }, []);
  useEffect(() => {
    if (show) {
      document
        .getElementsByTagName("body")[0]
        .classList.add("show-reservation");
    } else {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("show-reservation");
    }
  }, [show]);
  return (
    <>
      <button
        className="block ml-auto mr-auto mt-3 mb-6 pt-1.5 pb-1.5 md:hidden text-purple bg-pink text-14 font-f-2"
        style={{ width: 150 }}
        onClick={() => setShow(!show)}
      >
        Reservasi
      </button>
      <div
        className={`fixed z-50 overflow-auto top-0 left-0 right-0 bottom-0 bg-opacity-80 bg-black ${
          show ? "block" : "hidden"
        }`}
      >
        <div className="pt-6">
          <div className="pr-2 text-right">
            <button onClick={() => setShow(false)}>
              <ClosePink />
            </button>
          </div>
          <div className="p-2">
            <Reservation />
          </div>
        </div>
      </div>
    </>
  );
}
