import Reservation from "./components/Reservation";
import GoToReservation from "./components/GoToReservation";
import Reveal from "./components/AnimReveal";
import useData from "./hooks/useData";
import ErrorPage from "./components/ErrorPage";

export default function InfoSekolah() {
  const { data, loading } = useData("/singletons/get/page_info_sekolah");
  if (loading) return null;
  if (!loading && !data) return <ErrorPage />;
  return (
    <div>
      <div className="bg-purple">
        <div
          className="bg-center bg-cover"
          style={{
            backgroundImage: `url(${
              process.env.REACT_APP_BASE_URL + data.banner.path
            })`,
            height: "calc(100vh - 50px)",
          }}
        />
        <GoToReservation />
        <div className="container pt-4 pb-4 text-center text-white md:pt-13 md:pb-13">
          <div className="block pl-2 pr-2 md:pl-0 md:pr-0 md:flex ">
            <div className="md:w-6/12 md:pr-3">
              <Reveal>
                <img
                  src={process.env.REACT_APP_BASE_URL + data.image_1.path}
                  width="100%"
                  alt="menu-1"
                />
              </Reveal>
            </div>
            <div className="pt-4 text-left md:w-6/12 md:pt-0 md:pt-4 md:pl-3">
              <div className="pb-3 text-32 md:text-48 font-f-2">
                <Reveal>{data.title_1}</Reveal>
              </div>
              <div className="text-16 md:text-21">
                <Reveal>
                  <p dangerouslySetInnerHTML={{ __html: data.description_1 }} />
                </Reveal>
              </div>
            </div>
          </div>
          <div className="block pl-2 pr-2 mt-10 md:pl-0 md:pr-0 md:flex">
            <div className="md:hidden">
              <Reveal>
                <img
                  src={process.env.REACT_APP_BASE_URL + data.image_2.path}
                  width="100%"
                  alt="menu-1"
                />
                <div className="pt-1 text-left text-16 md:text-21">
                  {data.title_2}
                </div>
              </Reveal>
            </div>
            <div className="pt-4 text-left md:pr-4 md:w-5/12">
              <div className="text-16 md:text-21">
                <Reveal>
                  <div
                    dangerouslySetInnerHTML={{ __html: data.description_2 }}
                  />
                </Reveal>
              </div>
            </div>
            <div className="hidden w-7/12 pl-3 md:block">
              <Reveal>
                <img src="/img/info-sekolah-3.jpg" width="100%" alt="menu-1" />
                <div className="pt-1 text-left text-21">{data.title_2}</div>
              </Reveal>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden md:block">
        <Reservation />
      </div>
    </div>
  );
}
